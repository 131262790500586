<template>
  <div
    class="iotplt-card-box"
    style="margin-bottom: 10px"
  >
    <a-form class="iotplt-nested-search-form" :form="form" @submit="handleSearch">
      <a-row :gutter="24">
        <a-col :xl="8" :xxl="6">
          <a-form-item label="订单状态">
            <a-select
              mode="multiple"
              :loading="loadingStatusOptions"
              @focus="fetchAgentPurchaseOrderStatusOptions"
              v-decorator="['status']"
              allow-clear
            >
              <a-select-option
                v-for="status in statusOptions"
                :key="status.value"
                :value="status.value"
              >
                {{ status.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="套餐名称">
            <a-input
              v-decorator="['product_name', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="收货方式">
            <a-select
              v-decorator="['pickup_way']"
              allow-clear
            >
              <a-select-option key="0" value="0">
                商家邮寄
              </a-select-option>
              <a-select-option key="1" value="1">
                客户自提
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="订单编号">
            <a-input
              v-decorator="['order_no', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="项目编号">
            <a-input
              v-decorator="['item_no', {
                normalize: this.$lodash.trim
              }]"
              allow-clear
            />
          </a-form-item>
        </a-col>

        <a-col :xl="8" :xxl="6" v-show="expand">
          <a-form-item label="订单创建时间">
            <a-range-picker v-decorator="['created_at']" />
          </a-form-item>
        </a-col>

        <a-col :span="6">
          <a-form-item>
            <a-space>
              <a-button type="primary" html-type="submit">
                搜索
              </a-button>
              <a-button @click="handleReset">
                刷新
              </a-button>
              <a-button :style="{ fontSize: '12px', lineHeight: '32px' }" @click="toggle">
                更多条件
                <a-icon :type="expand ? 'up' : 'down'" />
              </a-button>
            </a-space>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
import { findAgentPurchaseOrderStatusOptions } from '@/api/agent_purchase_order'
import { formatRangeBeginDate, formatRangeEndDate } from '@/utils/time'

export default {
  name: 'SearchAgentPurchaseOrder',
  data() {
    return {
      expand: false,
      statusOptions: [],
      loadingStatusOptions: false,
      form: this.$form.createForm(this, { name: 'agent_purchase_order_search' })
    }
  },
  methods: {
    toggle() {
      this.expand = !this.expand
    },

    fetchAgentPurchaseOrderStatusOptions() {
      this.loadingStatusOptions = true
      findAgentPurchaseOrderStatusOptions().then((res) => {
        this.statusOptions = res.data
        this.loadingStatusOptions = false
      })
    },

    handleSearch(e) {
      e.preventDefault()
      const fieldsValue = this.form.getFieldsValue()
      const values = {
        ...fieldsValue,
        created_at_begin: formatRangeBeginDate(fieldsValue.created_at),
        created_at_end: formatRangeEndDate(fieldsValue.created_at)
      }
      this.$emit('submit', values)
    },

    handleReset() {
      this.form.resetFields()
      this.$emit('submit', {})
    }
  }
}
</script>
