<template>
  <div>
    <search-agent-purchase-order @submit="submitSearch" />

    <a-spin :spinning="loading">
      <div v-if="isDataExist">
        <div
          v-for="order in data"
          :key="order.id"
        >
          <div class="iotplt-card-box" style="margin-bottom: 20px">
            <a-row class="order-header">
              <a-col :span="16">
                <a-row>
                  <a @click="showInfoModal(order.id)">{{ order.order_no }}</a>
                </a-row>

                <a-row>
                  <a-col :span="8">
                    {{ order.created_at }}
                  </a-col>
                  <a-col :span="4">
                    {{ order.status }}
                  </a-col>
                  <a-col :span="4">
                    {{ order.pickup_way }}
                  </a-col>
                  <a-col :span="4">
                    {{ order.card_count }}
                  </a-col>
                  <a-col :span="4">
                    ￥ {{ order.order_fee | formatCurrency }}
                  </a-col>
                </a-row>
              </a-col>

              <a-col :span="8">
                <div class="iotplt-table-operation-buttons">
                  <a-space>
                    <a-button
                      type="primary"
                      v-if="['checiotplt', 'processing'].includes(order.status_slug)"
                      @click="showModifyAddressModal(order)"
                      @completed="fetchData"
                    >修改收货地址</a-button>
                    <a-button
                      type="primary"
                      v-if="order.is_can_cancel"
                      @click="showCancelOrder(order.id)"
                      @completed="fetchData"
                    >取消订单</a-button>
                  </a-space>
                </div>
              </a-col>
            </a-row>
            <agent-purchase-items-list
              :items="order.agent_purchase_items"
              :loading="loading"
              @completed="fetchData"
            />
          </div>
        </div>
      </div>
      <div v-else>
        <a-empty />
      </div>
    </a-spin>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 修改收货地址模态框 -->
    <modify-address
      v-if="isShowModifyAddressModal"
      :visible.sync="isShowModifyAddressModal"
      :id="modifyingId"
      :pickup-way="modifyRecordPickupWay"
      @completed="fetchData"
    />

    <!-- 取消订单模态框 -->
    <cancel-agent-purchase-order
      v-if="isShowCancelModal"
      :visible.sync="isShowCancelModal"
      :id="cancellingId"
      @completed="fetchData"
    />

    <!-- 详情模态框 -->
    <show-agent-purchase-order
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
      @completed="fetchData"
    />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { findAgentPurchaseOrders } from '@/api/agent_purchase_order'
import AgentPurchaseItemsList from '@/views/agent_purchase_items/index'
import SearchAgentPurchaseOrder from '@/views/agent_purchase_orders/Search'
export default {
  name: 'AgentPurchaseOrderList',
  components: {
    Pagination,
    AgentPurchaseItemsList,
    SearchAgentPurchaseOrder,
    ModifyAddress: () => import('@/views/agent_purchase_orders/modify_address/index'),
    CancelAgentPurchaseOrder: () => import('@/views/agent_purchase_orders/Cancel'),
    ShowAgentPurchaseOrder: () => import('@/views/agent_purchase_orders/Show')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowModifyAddressModal: false,
      isShowCancelModal: false,
      isShowInfoModal: false,
      showingId: 0, // 正在显示详情的订单id
      modifyingId: 0, // 正在修改的订单id
      cancellingId: 0, // 正在取消的订单id
      modifyRecordPickupWay: '',
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: '项目编号',
          dataIndex: 'id'
        }
      ]
    },

    agentId() {
      return this.$store.getters.userAgentId
    },

    isDataExist() {
      return this.data.length > 0
    }
  },
  methods: {
    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    showModifyAddressModal(record) {
      this.modifyingId = record.id
      this.modifyRecordPickupWay = record.pickup_way_slug
      this.isShowModifyAddressModal = true
    },

    showCancelOrder(recordId) {
      this.cancellingId = recordId
      this.isShowCancelModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      findAgentPurchaseOrders(Object.assign(
        { agent_id: this.agentId }, this.query)
      ).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    }

  }
}
</script>

<style lang="less" scoped>
.order-header {
  margin-bottom: 5px;
  color: #8b8b8b;
}
</style>
