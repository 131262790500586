<template>
  <div>
    <a-table
      size="middle"
      :columns="columns"
      :data-source="items"
      :loading="loading"
      :scroll="{ x: 'max-content' }"
      :pagination="false"
      row-key="id"
    >
      <div slot="item_no" slot-scope="item_no, record">
        <a @click="showInfoModal(record.id)">{{ item_no }}</a>
      </div>
    </a-table>

    <!-- 详情模态框 -->
    <show-agent-purchase-item
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />
  </div>
</template>

<script>
import { formatFloat } from '@/utils/filter'

export default {
  name: 'AgentPurchaseItemsList',
  components: {
    ShowAgentPurchaseItem: () => import('@/views/agent_purchase_items/Show')
  },
  props: {
    items: {
      type: Array,
      required: true,
      default() {
        return []
      }
    },
    loading: {
      type: Boolean,
      required: true,
      default() {
        return true
      }
    }
  },
  data() {
    return {
      isShowInfoModal: false,
      showingId: 0 // 正在展示详情的项目id
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '项目状态',
          dataIndex: 'status',
          fixed: 'left'
        },
        {
          title: '项目编号',
          dataIndex: 'item_no',
          scopedSlots: { customRender: 'item_no' },
          fixed: 'left'
        },
        {
          title: '套餐名称',
          dataIndex: 'product_name',
          ellipsis: true
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '用户业务模式',
          dataIndex: 'user_business_type'
        },
        {
          title: '套餐周期',
          dataIndex: 'service_cycle'
        },
        {
          title: '套餐容量',
          dataIndex: 'package_capacity'
        },
        {
          title: '卡品',
          dataIndex: 'card_type'
        },
        {
          title: '周期数',
          dataIndex: 'service_period'
        },
        {
          title: '采购卡数(张)',
          dataIndex: 'card_count'
        },
        {
          title: '套餐价格(元)',
          dataIndex: 'distributor_price',
          customRender: formatFloat,
          scopedSlots: { customRender: 'distributor_price' }
        },
        {
          title: '卡板价格',
          dataIndex: 'card_type_price'
        },
        {
          title: '套餐费用(元)',
          dataIndex: 'product_fee',
          customRender: formatFloat,
          scopedSlots: { customRender: 'product_fee' }
        },
        {
          title: '卡板费用(元)',
          dataIndex: 'card_fee',
          customRender: formatFloat,
          scopedSlots: { customRender: 'card_fee' }
        },
        {
          title: '小计(元)',
          dataIndex: 'total_fee',
          customRender: formatFloat,
          scopedSlots: { customRender: 'total_fee' }
        },
        {
          title: '备注',
          width: 150,
          dataIndex: 'remark',
          ellipsis: true
        }
      ]
    }
  },
  methods: {
    showInfoModal(recordId) {
      this.showingId = recordId
      this.isShowInfoModal = true
    },

    fetchData() {
      this.$emit('completed')
    }
  }
}
</script>

<style scoped>

</style>
